import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { createTypography } from '../../styles/base';

const ErrorMessage = styled.div(({ theme: { colours, typography } }) => [
  createTypography(typography.body01),
  css`
    height: 25px;
    color: ${colours.text.critical};
    font-size: 12px;
  `,
]);

export default ErrorMessage;
