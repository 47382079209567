import { Translate } from 'next-translate';
import { isNumber } from '@utils/helperUtils';

export const getCounterLabel = ({
  label,
  maxAge,
  minAge,
  t,
}: {
  label: string;
  maxAge?: number | null;
  minAge?: number | null;
  t: Translate;
}) => {
  if (isNumber(minAge) && isNumber(maxAge)) {
    return `${t(label)} (${minAge}-${maxAge})`;
  }

  if (isNumber(minAge)) {
    return `${t(label)} (${minAge}+)`;
  }

  return label;
};
