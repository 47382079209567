import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  centerHorizontally,
  centerVertically,
  createTypography,
} from '../../styles/base';
import CounterButton from '../CounterButton/CounterButton';

export interface Props {
  canDecrement?: boolean;
  canIncrement?: boolean;
  decrement: () => void;
  id?: string;
  increment: () => void;
  value: number;
}

const Container = styled.div(({ theme: { colours, typography } }) => [
  createTypography(typography.body01),
  centerVertically,
  css`
    color: ${colours.text.default};
  `,
]);

const ValueContainer = styled.div([
  centerHorizontally,
  css`
    min-width: 40px;
    font-weight: bold;
  `,
]);

const Counter = ({
  canDecrement,
  canIncrement,
  decrement,
  id,
  increment,
  value,
}: Props) => {
  return (
    <Container>
      <CounterButton
        disabled={!canDecrement}
        id={`${id}Decrement`}
        isMinus
        onClick={decrement}
      />
      <ValueContainer>{value}</ValueContainer>
      <CounterButton
        disabled={!canIncrement}
        id={`${id}Increment`}
        onClick={increment}
      />
    </Container>
  );
};

export default Counter;
