import { AriaAttributes } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Translate } from 'next-translate';
import useTranslation from 'next-translate/useTranslation';
import Notification from '@ui-v2/core/Notification/Notification';
import { createAnArray, isNumber } from '@utils/helperUtils';
import { centerVertically, createTypography } from '../../styles/base';
import Counter from '../Counter/Counter';
import Dropdown from '../Dropdown/Dropdown';
import { getCounterLabel } from './passengerCounterUtils';
import { PassengerTypeProp } from './passengerTypes';

export interface Props {
  addPassenger: (paxType: string) => void;
  ariaLabel?: AriaAttributes['aria-label'];
  className?: string;
  disclaimer?: string;
  passengerTypes: PassengerTypeProp[];
  removePassenger: (paxType: string) => void;
  renderLabel?: ({
    label,
    maxAge,
    minAge,
    t,
  }: {
    label: string;
    maxAge?: number | null;
    minAge?: number | null;
    t: Translate;
    type: string;
  }) => React.ReactNode;
  updateAge?: (updateIndex: number, newAge: number, paxType: string) => void;
}

const Container = styled.div(({ theme: { typography } }) => [
  createTypography(typography.body01),
  css`
    display: flex;
    flex-direction: column;
  `,
]);

export const CounterContainer = styled.div(({ theme: { spacings } }) => [
  centerVertically,
  css`
    display: flex;
    justify-content: space-between;

    & + & {
      margin-top: ${spacings['16']}px;
    }
  `,
]);

const AgeContainer = styled.div(
  ({ theme: { colours, spacings } }) => css`
    padding-top: ${spacings['16']}px;
    border-top: 1px solid ${colours.border.default};
    margin-top: ${spacings['16']}px;
  `,
);

const AgeInputContainer = styled.div(
  ({ theme: { spacings } }) => css`
    & + & {
      margin-top: ${spacings['16']}px;
    }
  `,
);

const PassengerCounter = ({
  addPassenger,
  className,
  disclaimer,
  passengerTypes,
  removePassenger,
  renderLabel = getCounterLabel,
  updateAge,
}: Props) => {
  const { t } = useTranslation();

  const getArrayDropdowns = (minAge: Maybe<number>, maxAge: Maybe<number>) => {
    if (minAge === undefined || minAge === null || !maxAge) {
      return [];
    }

    return createAnArray<number>(maxAge - minAge + 1).map((i) => ({
      label: String(i + minAge),
      value: String(i + minAge),
    }));
  };

  const passengerTypesWithAges = passengerTypes.filter(
    (paxType) =>
      isNumber(paxType.minAge) && isNumber(paxType.maxAge) && paxType.count > 0,
  );

  const paxTypeTranslationMapper: Record<
    Exclude<PassengerTypeProp['type'], 'adult'>,
    string
  > = {
    child: t('c').toLocaleLowerCase(),
    infant: t('infant').toLocaleLowerCase(),
  };

  return (
    <Container className={className}>
      {passengerTypes.map(
        ({ count, label, maxAge, maxCount, minAge, minCount, type }) => (
          <CounterContainer key={type}>
            <div>{renderLabel({ t, minAge, maxAge, label, type })}</div>
            <Counter
              canDecrement={
                Boolean(minCount && count > minCount) ||
                (minCount === 0 && count > 0)
              }
              canIncrement={Boolean(maxCount && count < maxCount)}
              decrement={() => removePassenger(type)}
              id={type}
              increment={() => addPassenger(type)}
              value={count}
            />
          </CounterContainer>
        ),
      )}
      {passengerTypesWithAges.length > 0 && updateAge && (
        <AgeContainer>
          {passengerTypesWithAges.map(
            ({ ages, id, maxAge, minAge, type }) =>
              ages?.map((age, ageIndex) => {
                const paxTypeName = paxTypeTranslationMapper[type];

                return (
                  <AgeInputContainer
                    key={`${type}-${id}-${ageIndex.toString()}`}
                  >
                    <Dropdown
                      id={`${type}-${id}`}
                      label={t(
                        'Age of {{paxType}} {{paxTypeNumber}}',
                        {
                          paxType: paxTypeName,
                          paxTypeNumber: ageIndex + 1,
                        },
                        { default: `Age of ${type} ${ageIndex + 1}` },
                      )}
                      onChange={(value) =>
                        updateAge(ageIndex, Number(value), type)
                      }
                      placeholder={t('Select age')}
                      value={age ? String(age) : undefined}
                      values={[
                        { values: getArrayDropdowns(minAge || 0, maxAge) },
                      ]}
                    />
                  </AgeInputContainer>
                );
              }),
          )}
          {disclaimer && (
            <Notification mt={16} variant="warning">
              {disclaimer}
            </Notification>
          )}
        </AgeContainer>
      )}
    </Container>
  );
};

export default PassengerCounter;
