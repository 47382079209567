import { css } from '@emotion/react';
import styled from '@emotion/styled';
import hideVisually from 'polished/lib/mixins/hideVisually';
import { createTypography } from '../../styles/base';

const Label = styled.label<{ labelHidden?: boolean }>(
  ({ labelHidden, theme: { spacings, typography } }) => [
    createTypography(typography.heading07),
    labelHidden && hideVisually(),
    css`
      margin-bottom: ${spacings[4]}px;
    `,
  ],
);

export default Label;
