import { useEffect, useState } from 'react';
import usePartnerRouter from './usePartnerRouter';

export const useIsRouting = () => {
  const [isRouting, setIsRouting] = useState(false);
  const router = usePartnerRouter();

  const handleRouteStart = () => setIsRouting(true);
  const handleRouteEnd = () => setIsRouting(false);

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteStart);
    router.events.on('routeChangeComplete', handleRouteEnd);
    router.events.on('routeChangeError', handleRouteEnd);

    return () => {
      router.events.off('routeChangeStart', handleRouteStart);
      router.events.off('routeChangeComplete', handleRouteEnd);
      router.events.off('routeChangeError', handleRouteEnd);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isRouting,
  };
};
